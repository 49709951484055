// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-content-detail-js": () => import("./../../../src/templates/contentDetail.js" /* webpackChunkName: "component---src-templates-content-detail-js" */),
  "component---src-templates-content-homepage-js": () => import("./../../../src/templates/contentHomepage.js" /* webpackChunkName: "component---src-templates-content-homepage-js" */),
  "component---src-templates-content-news-item-js": () => import("./../../../src/templates/contentNewsItem.js" /* webpackChunkName: "component---src-templates-content-news-item-js" */),
  "component---src-templates-content-news-listing-js": () => import("./../../../src/templates/contentNewsListing.js" /* webpackChunkName: "component---src-templates-content-news-listing-js" */),
  "component---src-templates-content-transition-listing-js": () => import("./../../../src/templates/contentTransitionListing.js" /* webpackChunkName: "component---src-templates-content-transition-listing-js" */)
}

